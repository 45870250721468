import { MyDropzone } from "./components/dropzone";
import { useState, useEffect } from "react";
//Loader
import { ThreeDots } from "react-loader-spinner";
import "./App.css";

function App() {
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [error, setError] = useState(false);
  const [reRender, setRerender] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {}, [reRender]);

  const fileToBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const getSimilarityScore = async () => {
    if (selectedImages.length < 2) {
      setError(true);
      setErrorMessage("Please add images");
    } else {
      setError(true);
      setErrorMessage("");
      setSubmit(true);
      setLoading(true);
      let imgArr = [];
      for (let file of selectedImages) {
        let base64Image = await fileToBase64(file);
        imgArr.push(base64Image.split(",").pop());
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: imgArr }),
      };
      fetch(
        "https://deeplobe-lambda-api-v1.herokuapp.com/imageSimilarity",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);

          setResult(data.response);
        })
        .catch((e) => {
          setError(true);
          setErrorMessage("Please try with other images");
        });
    }
  };
  const resetAll = () => {
    setSubmit(false);
    setLoading(false);
    setError(false);
    setErrorMessage("");
    setResult({});
    setSelectedImages([]);
  };
  const renderImages = (images) => {
    return images.map((img, index) => {
      return (
        <div className="m-0" key={index}>
          <img
            src={URL.createObjectURL(img)}
            alt=""
            className="me-1"
            style={{
              height: 70,
              width: 70,
              borderRadius: 5,
              // opacity: photo.selected ? 0.3 : 1,
            }}
          />
          <div className="overlay">
            <img
              src="./delete-icon.svg"
              alt="delete-icon"
              style={{
                marginTop: "-156px",
                color: "#6152D9",
                marginLeft: "56px",
              }}
              onClick={() => {
                const temp = selectedImages;
                temp.splice(index, 1);
                setSelectedImages(temp);
                setRerender(!reRender);
              }}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div style={{ backgroundColor: "#F7FAFC", height: "100vh" }}>
      <div>
        <nav className="navbar navbar-light bg-white shadow-sm">
          <div className="container my-2">
            <div>
              <img
                src="https://deeplobe.s3.ap-south-1.amazonaws.com/deeplobe.svg"
                alt="Brand"
              />
            </div>
            <div>
              <a
                className="btn py-2 px-3"
                style={{
                  backgroundColor: "#6152D9",
                  color: "white",
                  fontSize: "14px",
                  borderRadius: "6px",
                }}
                href="https://deeplobe.ai/contact-us/"
              >
                Contact Sales
              </a>
            </div>
          </div>
        </nav>
        <div className="container bg-white ">
          <div className="p-4 rounded m-5">
            <div>
              <p className="fw-bold font-poppins font-20 font-weight-600 ">
                Image Similarity model
              </p>
              <p className="font-inter font-16">
                Compare two images to check how similar they are. We’ll give you
                a score that represents the similarity levels of the images -
                the bigger the score is, the more similar and identical the
                images are. You can test our model by selecting from the given
                sample images. You can upload the images from your systems and
                simply click “Run Model”.
              </p>
              <hr className="my-4" style={{ color: "#E4E4E4" }} />
              <h4 className="font-18 font-inter font-weight-500 mb-3">
                Upload any two images to check similarity score
              </h4>
              <MyDropzone
                setSelectedImages={setSelectedImages}
                maxNumberOfImages={2}
              />

              {error ? (
                <small className="text-danger">{errorMessage}</small>
              ) : null}
              <div className="d-flex flex-wrap bg-white pt-2 container large-2">
                {renderImages(selectedImages)}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn  btn-sm ms-3 font-inter border-0"
                  onClick={resetAll}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm py-2 px-4 font-inter font-14 ms-3"
                  style={{
                    backgroundColor: "#6152D9",
                    color: "white",
                    fontSize: "14px",
                    borderRadius: "6px",
                  }}
                  onClick={getSimilarityScore}
                >
                  Run Model
                </button>
              </div>
              <hr className="my-4" style={{ color: "#E4E4E4" }} />
              <div>
                {submit ? (
                  <div className="container">
                    <h3 className="my-3 result-title font-20 font-weight-600">
                      Model results
                    </h3>
                    {loading ? null : (
                      <div>
                        <h5 className="d-flex flex-row mt-4 result-content fw-bold">
                          Similarity score:{" "}
                          <span
                            className="ms-1 font-inter"
                            style={{ color: "#6152D9" }}
                          >
                            {parseFloat(result.score).toFixed(3)} out 10
                          </span>
                        </h5>
                      </div>
                    )}
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <ThreeDots
                          type="ThreeDots"
                          color="#6152D9"
                          height={100}
                          width={100}
                        />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-around mt-3">
                        <div className="d-flex flex-column w-100">
                          <div className="text-left">
                            <h6 className="mt-2">Image 1</h6>
                          </div>
                          <div className="d-flex justify-content-center w-100 h-100 bg-light p-5">
                            <img
                              className="img-fluid"
                              src={result.image1}
                              style={{ maxHeight: "90%", maxWidth: "90%" }}
                              alt="passed"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column w-100 ms-3">
                          <div className="text-left">
                            <h6 className="mt-2">Image 2</h6>
                          </div>
                          <div className="d-flex justify-content-center w-100 h-100 bg-light p-5">
                            <img
                              className="img-fluid"
                              src={result.image2}
                              style={{ maxHeight: "90%", maxWidth: "90%" }}
                              alt="annotated"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="text-center w-100 container"
                    style={{ marginTop: "65px", marginBottom: "65px" }}
                  >
                    <div>
                      <p className="text-secondary">
                        You will see the results here!
                      </p>
                      <img src="./brain-icon.svg" alt="intelligence" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-0">
        <small className="font-14 text-secondary" style={{ color: "#808191" }}>
          © Copyright SoulPage IT Solutions 2022. All rights reserved.
        </small>
      </div>
    </div>
  );
}

export default App;

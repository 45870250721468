import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 10,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#F7FAFC",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function MyDropzone({ setSelectedImages, maxNumberOfImages }) {
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImages(acceptedFiles);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: maxNumberOfImages,
    maxFilesize: 200,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <>
      <div className="" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {/* <div className="d-flex"> */}
        <small className="font-inter text-center">
          Drop your image(s) here or
          <a
            className="mb-3 font-inter fw-bold"
            style={{
              cursor: "pointer",
              color: "#6152D9",
              textDecoration: "none",
            }}
          >
            {" "}
            browse
          </a>
        </small>
        {/* </div>  */}

        <small className="font-inter text-center">
          Only .jpeg, .jpg and .png files with max size of 75kb
        </small>
      </div>
    </>
  );
}

export default MyDropzone;
